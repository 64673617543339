.main {
  margin-left: 110px;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}
