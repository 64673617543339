.contact.section {
  padding-bottom: 6.25rem;
}

.contact__container {
  grid-template-columns: 4fr 8fr;
  column-gap: 1.875rem;
}

.contact__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
}

.contact__form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}

.contact__form-div {
  position: relative;
  margin-bottom: 1.875rem;
  height: 3.75rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow);
  background-color: var(--container-color);
  color: var(--text-color);
  border: none;
  outline: none;
  border-radius: 1.875rem;
  padding: 0.625rem 1.875rem;
  z-index: 1;
}

.contact__form-area {
  height: 10.25rem;
}

.contact__form-area textarea {
  resize: none;
}

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 300px 360px;
    justify-content: center;
  }
  .contact__form-group {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 310px;
    row-gap: 1.875rem;
  }

  .contact__info {
    text-align: center;
  }
}

@media screen and (max-width: 350px) {
  .contact__container {
    grid-template-columns: 1fr;
  }
}

.loader {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 12px;
  height: 12px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.d-none {
  display: none;
}

:root {
  --infoBlue: #2e86de;
  --infoBlueLight: #eff6fc;
  --successGreen: #329f5d;
  --successGreenLight: #eff7f2;
  --errorRed: #c2160a;
  --errorRedLight: #faedec;
  --warningOrange: #fa7f05;
  --warningOrangeLight: #fff5eb;
  --bodyTextColour: #212121;
}
.alert {
  margin-block: 2.5rem;
  padding: 1.25rem;
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: max-content auto;
  border-radius: 4px;
  border-width: 4px;
  border-left-style: solid;
  transition: 0.12s ease;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: soft-light;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 1) 56%,
      rgba(2, 0, 36, 0.1) 82%
    );
    z-index: 1;
  }
  .icon,
  .content {
    z-index: 2;
  }
  .icon {
    line-height: 1;
  }
  .title {
    font-weight: 700;
    margin-bottom: 0.75rem;
  }
  .content {
    max-width: 60ch;
  }
  &.alert--info {
    background-color: var(--infoBlueLight);
    border-left-color: var(--infoBlue);
    .icon {
      color: var(--infoBlue);
    }
  }
  &.alert--success {
    background-color: var(--successGreenLight);
    border-left-color: var(--successGreen);
    .icon {
      color: var(--successGreen);
    }
  }
  &.alert--error {
    background-color: var(--errorRedLight);
    border-left-color: var(--errorRed);
    .icon {
      color: var(--errorRed);
    }
  }
  &.alert--warning {
    background-color: var(--warningOrangeLight);
    border-left-color: var(--warningOrange);
    .icon {
      color: var(--warningOrange);
    }
  }
}

@media (max-width: 767px) {
  .alert {
    grid-template-columns: auto;
    padding: 1rem;
    grid-gap: 0.75rem;
    .icon {
      font-size: 1.5rem;
    }
    .title {
      margin-bottom: 0.5rem;
    }
  }
}
